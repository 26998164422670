import React, { useState, useEffect } from "react";
import logo from "../images/logo1.png";
import { Link } from "react-scroll";
import { Helmet } from "react-helmet-async"; // Updated to react-helmet-async for consistency with App.js

function Navbar() {
  const [nav, setNav] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu visibility

  useEffect(() => {
    const changeBackground = () => {
      setNav(window.scrollY >= 50);
    };

    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  // Toggle the menu state on mobile
  const handleMenuToggle = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  return (
    <>
      <Helmet>
        <title>Pak Loaders | Packers and Movers in Pakistan</title>
        <meta
          name="description"
          content="Pak Loaders provides top-notch Packers and Movers services across Pakistan. Explore our Home Shifting, Office Moving, and Corporate Relocation services."
        />
        <meta
          name="keywords"
          content="Packers and Movers, Home Shifting, Office Moving, Corporate Relocation, Pakistan Movers"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <nav className={`nav ${nav ? "active" : ""}`}>
        <Link to="main" className="logo">
          <img src={logo} alt="Pak Loaders Logo" loading="lazy" />
        </Link>

        {/* Hidden Checkbox for Menu Toggle */}
        <input
          type="checkbox"
          id="menu-btn"
          className="menu-btn"
          checked={isMenuOpen} // Tied to state
          onChange={handleMenuToggle} // Toggling the menu state
          style={{ display: "none" }}
        />

        {/* Menu Icon */}
        <label className="menu-icon" htmlFor="menu-btn" aria-label="Toggle menu">
          <span className="nav-icon"></span>
        </label>

        {/* Menu Items */}
        <ul className={`menu ${isMenuOpen ? "open" : ""}`}>
          <li>
            <Link
              to="main"
              smooth={true}
              duration={500}
              onClick={() => setIsMenuOpen(false)} // Close menu on click
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="features"
              smooth={true}
              duration={500}
              onClick={() => setIsMenuOpen(false)}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              onClick={() => setIsMenuOpen(false)}
            >
              Get a Quote
            </Link>
          </li>
          <li>
            <Link
              to="about"
              smooth={true}
              duration={500}
              onClick={() => setIsMenuOpen(false)}
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              onClick={() => setIsMenuOpen(false)}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
