import React, { Suspense, lazy } from "react";
import { Helmet } from "react-helmet-async"; // Best practice: Use react-helmet-async
import Navbar from "./Components/Navbar";
import Header from "./Components/Header"; // Critical component, load immediately

// Lazy-load components for better performance
const Feature = lazy(() => import("./Components/Feature"));
const Offer = lazy(() => import("./Components/Offer"));
const About = lazy(() => import("./Components/About"));
const Contact = lazy(() => import("./Components/Contact"));

function App() {
  return (
    <div>
      {/* SEO Optimizations */}
      <Helmet>
        <title>Packers and Movers in Pakistan | Affordable Home Shifting | Pak Loaders</title>
        <meta
          name="description"
          content="Pak Loaders offers professional Packers and Movers services across Pakistan. Affordable home shifting, reliable moving services, and stress-free relocation."
        />
        <meta
          name="keywords"
          content="Packers and Movers Pakistan, Home Shifting Pakistan, Office Relocation Pakistan, Moving services, Transport, Pak Loaders"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Packers and Movers in Pakistan | Affordable Home Shifting | Pak Loaders" />
        <meta
          property="og:description"
          content="Affordable and reliable Packers and Movers services in Pakistan. Contact Pak Loaders for a stress-free move."
        />
        <meta property="og:image" content="https://pakloaders.com/og-image.jpg" /> {/* Replace with actual image URL */}
        <meta property="og:url" content="https://pakloaders.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Packers and Movers in Pakistan | Affordable Home Shifting | Pak Loaders" />
        <meta
          name="twitter:description"
          content="Pak Loaders provides professional Packers and Movers services. Affordable rates for home shifting and corporate moves."
        />
        <meta name="twitter:image" content="https://pakloaders.com/og-image.jpg" /> {/* Replace with actual image URL */}

        {/* Canonical URL */}
        <link rel="canonical" href="https://pakloaders.com" />

        {/* Preload Critical Resources */}
        <link rel="preload" href="/path-to-logo.png" as="image" /> {/* Replace with your logo path */}
        <link rel="preload" href="/path-to-whatsapp-icon.png" as="image" /> {/* Replace with your WhatsApp icon path */}
      </Helmet>

      <div className="App">
        <Navbar />
        <Header />

        {/* Lazy load below-the-fold components */}
        <Suspense fallback={<div>Loading...</div>}>
          <Feature />
          <Offer />
          <About />
          <Contact />
        </Suspense>
      </div>

      {/* WhatsApp Floating Button */}
      <a
        href="https://wa.me/923098791041"
        target="_blank"
        className="whatsapp-btn"
        rel="noopener noreferrer"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // Ensure this is valid
          alt="WhatsApp Button"
        />
      </a>
    </div>
  );
}

export default App;
